import "./App.css";
import { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import user1 from "./assets/user1.jpg";
import user2 from "./assets/user2.jpg";
import user3 from "./assets/user3.jpg";
import user4 from "./assets/user4.jpg";

function App() {
  const form = useRef();
  const form_Two = useRef();

  useEffect(() => {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };

    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add("header-scrolled");
        if (selectTopbar) {
          selectTopbar.classList.add("topbar-scrolled");
        }
      } else {
        selectHeader.classList.remove("header-scrolled");
        if (selectTopbar) {
          selectTopbar.classList.remove("topbar-scrolled");
        }
      }
    };

    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    };

    let navbarlinks = select("#navbar .scrollto", true);
    let selectHeader = select("#header");
    let selectTopbar = select("#topbar");
    let backtotop = select(".back-to-top");

    window.addEventListener("load", navbarlinksActive);
    onscroll(document, navbarlinksActive);

    if (selectHeader) {
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    window.addEventListener("load", toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }, []);

  const handleSendMessage = (e) => {
    e.preventDefault();
    const isValid = form.current.reportValidity();
    if (isValid) {
      const nameInput = form.current.elements.from_name;
      const emailInput = form.current.elements.from_email;
      const subjectInput = form.current.elements.subject;
      const messageInput = form.current.elements.message;
      if (nameInput.value.length <= 5) {
        alert("Please enter your full name");
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailInput.value)) {
        alert("Please enter a valid email address");
        return;
      }
      if (subjectInput.value.trim().length <= 5) {
        alert("Subject should be more than 5 characters");
        return;
      }
      if (messageInput.value.trim().length <= 20) {
        alert("Message should be more than 20 characters");
        return;
      }
      emailjs
        .sendForm(
          "service_up85e7m",
          "template_f94n1fa",
          form.current,
          "Pe2ghPVx6DKK90ULp"
        )
        .then(
          (result) => {
            alert("Your message has been sent");
            e.target.reset();
          },
          (error) => {
            alert("Oops something went wrong");
          }
        );
    } else {
      alert("Please fill out all required fields correctly");
    }
  };
  const handleJobSearch = (e) => {
    e.preventDefault();
    const isValid = form_Two.current.reportValidity();
    if (isValid) {
      const nameInput = form_Two.current.elements.from_name;
      const emailInput = form_Two.current.elements.from_email;
      const phoneInput = form_Two.current.elements.phone_number;
      const messageInput = form_Two.current.elements.message;
      if (nameInput.value.length <= 5) {
        alert("Please enter your full name");
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailInput.value)) {
        alert("Please enter a valid email address");
        return;
      }
      if (phoneInput.value.trim().length !== 10) {
        alert("Phone should be 10 characters");
        return;
      }
      if (messageInput.value.trim().length <= 20) {
        alert("Message should be more than 20 characters");
        return;
      }
      emailjs
        .sendForm(
          "service_up85e7m",
          "template_qobfl09",
          form_Two.current,
          "Pe2ghPVx6DKK90ULp"
        )
        .then(
          (result) => {
            alert("Your message has been sent");
            e.target.reset();
          },
          (error) => {
            alert("Oops something went wrong");
          }
        );
    } else {
      alert("Please fill out all required fields correctly");
    }
  };

  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h3 className="logo me-auto">
            <a href="index.html">R1 Technologies Services</a>
          </h3>
          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#home">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#doctors">
                  Reviews
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          <a href="#appointment" className="appointment-btn scrollto">
            <span className="d-none d-md-inline">Looking for </span>
            Jobs
          </a>
        </div>
      </header>
      <section id="home" className="d-flex align-items-center">
        <div className="container">
          <h1>
            Welcome to <br/> R1  technologies Services <br />
          </h1>
          <h2>
            R1 technologies Services is the leading provider of Revenue Cycle
            Management <br /> process and Analytics Solutions in the healthcare
            industry.
          </h2>
          <a href="#about" className="btn-get-started scrollto">
            Learn More
          </a>
        </div>
      </section>
      <main id="main">
        <section id="featured-services" className="featured-services">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <i className="bi bi-info-square-fill"></i>
                  </div>
                  <h4 className="title">
                    <a href="/"> Credentialing And Eligibility Verification</a>
                  </h4>
                  <p className="description">
                    We ensure provider Credentialed with insurance companies on
                    timely manner. Also verify the patient eligibility with
                    insurance companies.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <i className="fas fa-dna"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">All Medical Billing Services</a>
                  </h4>
                  <p className="description">
                    We ensure provider Demographic Entry, Charge Entry and
                    Coding with 24-48 hrs. turnaround time. Also, accuracy
                    benchmark of 97% on SLA’s. Claims billed in a timely manner
                    with insurance companies on timely manner. Also verify the
                    patient eligibility with insurance companies.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <i className="bi bi-currency-dollar"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">Revenue Cycle Management</a>
                  </h4>
                  <p className="description">
                    We’re currently using Kareo billing software to track
                    patient care episodes from registration and appointment
                    scheduling to the final payment of a balance.
                  </p>
                </div>
              </div>
              <br />
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bi bi-person"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">Account Receivable</a>
                  </h4>
                  <p className="description">
                    Following up dynamically with almost all carriers for denied
                    or outstanding claims. Also follow up with patients for
                    self-pay or for patient responsibilities due
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bi bi-code"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">Medical Coding</a>
                  </h4>
                  <p className="description">
                    Remote Coding for ICD-9, ICD-10, ICD-11, CPT-4 and HCPSC II
                    across all specialties. 24-48 Hrs. turnaround time for all
                    specialties.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bi bi-receipt"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">Billing and Claims Submission:</a>
                  </h4>
                  <p className="description">
                    Generating and submitting accurate claims to insurance
                    companies. <br />
                    Following up on unpaid claims and addressing denials..
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bi bi-credit-card"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">Payment Posting</a>
                  </h4>
                  <p className="description">
                    Recording and reconciling payments received from patients
                    and insurance companies. Managing adjustments and refunds
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="fab fa-servicestack"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">Denial Management</a>
                  </h4>
                  <p className="description">
                    Analyzing and addressing claim denials promptly.
                    Resubmitting corrected claims and appealing denials when
                    necessary.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About Us</h2>
              <p>
                R1 technologies Services owes its origins to the personal
                experience of its founders. After various tenures in the U.S
                healthcare industry, founders recognized a major lack of support
                for medical billing companies and healthcare payment systems.
                <br />
                Combining their domain expertise and business knowledge in the
                Revenue Cycle Management industry, R1 technologies Services was
                created in <span> 2018 </span> For this, continuous growth often
                involves increasing revenue, profitability, and number of
                Employees . Companies may achieve this through expanding their
                customer base, introducing new products or services, or
                optimizing operational efficiency. .
              </p>
            </div>
            <div className="row">
              <div
                className="col-xl-5 col-lg-6 video-box d-flex justify-content-center
     align-items-stretch position-relative"
              ></div>
              <div
                className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch
     justify-content-center py-5 px-lg-5"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-heart"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">HEALTH IS WEALTH</a>
                  </h4>
                  <p className="description">
                    The primary focus of healthcare providers is to restore and maintain patients' health. In RCM, ensuring accurate and efficient billing, coding, and reimbursement processes helps support this goal.
                    Health is Wealth in the RCM industry means prioritizing patient care, financial stability, operational efficiency, and compliance to ensure a thriving healthcare organization.
                  </p>
                </div>
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-health"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">EMERGENCY</a>
                  </h4>
                  <p className="description">
                    "EMERGENCY" can refer to various critical situations that require immediate attention to prevent financial losses, reputational damage, or compliance risks. Some examples System downtime ,Security breaches, Compliance audits,Natural disasters, Staffing crises, Payment delays,Regulatory changes.In each of these emergency scenarios, swift action, clear communication, and effective problem-solving are crucial to minimize the impact on patients, staff, and the organization's financial health.
                  </p>
                </div>
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-help-circle"></i>
                  </div>
                  <h4 className="title">
                    <a href="/">SERVICES </a>
                  </h4>
                  <p className="description">
                    services encompass a range of solutions designed to optimize financial performance, streamline operations, and enhance patient satisfaction. Some examples , Medical Billing: Accurate and efficient processing of claims, invoices, and payments, Claims Management,Patient Registration,Insurance Verification,Payment Processing, Account Receivable Management,Denial Management, Revenue Cycle Analytics, Compliance and Regulatory Support, Staffing and Training, System Implementation and Integration, Patient Engagement,
                    These services help healthcare providers and organizations optimize their revenue cycle, reduce costs,  and improve patient satisfaction.


                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="counts" className="counts">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <i className="fas fa-user-md"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="95"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>
                    <strong>Average</strong> Clean Claim Ratio
                  </p>
                  <a href="/">Find out more &raquo;</a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <i className="far fa-hospital"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="83"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>
                    <strong>Billed More Than</strong> Millions In Charges
                  </p>
                  <a href="/">Find out more &raquo;</a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <i className="fas fa-flask"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="30"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>
                    <strong>Less Than</strong> Days Outstanding A/R
                  </p>
                  <a href="/">Find out more &raquo;</a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <i className="fas fa-award"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="70"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>
                    <strong>Average</strong> Increase In Revenue
                  </p>
                  <a href="/">Find out more &raquo;</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <div className="section-title">
              <h2>Services</h2>
              <p>
                Our services in the healthcare industry is crucial for ensuring
                the financial stability and success of healthcare providers.{" "}
                <br />
                RCM services encompass a range of activities aimed at optimizing
                the revenue cycle, <br /> which involves the entire process of
                providing and receiving payment for healthcare services. Here
                are key roles and functions of RCM industry services.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-optimixation"></i>
                  </div>
                  <h4>
                    <a href="/">Optimizing Revenue Flow</a>
                  </h4>
                  <p>
                    We are help healthcare providers maximize revenue by
                    efficiently managing the entire billing and reimbursement
                    process.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-pills"></i>
                  </div>
                  <h4>
                    <a href="/">Medicine</a>
                  </h4>
                  <p>
                    Current Procedural Terminology (CPT) codes are used in
                    medical billing to identify and describe medical procedures
                    and services. Specific CPT codes are assigned for the
                    administration of medications, and these codes are included
                    in the billing process like HCPCS Level II Codes for
                    Drugs,National Drug Codes (NDC),Claim Submission for
                    Medications.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-hospital-user"></i>
                  </div>
                  <h4>
                    <a href="/">Pharmeologist</a>
                  </h4>
                  <p>
                    Pharmacists are healthcare professionals who are involved in
                    the preparation, dispensing, and management of medications.
                    In the context of medical billing, pharmacists may play a
                    role in providing information related to drug costs,
                    handling prescription billing, and ensuring that the correct
                    medications are billed and reimbursed appropriately.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-dna"></i>
                  </div>
                  <h4>
                    <a href="/">Blood Test</a>
                  </h4>
                  <p>
                    Medical billing for blood tests involves the process of
                    submitting claims for reimbursement to insurance companies
                    or other payers for the laboratory services provided. Here
                    are key points related to blood tests in medical billing and
                    Some insurance plans may require verification and
                    preauthorization for certain blood tests. Medical billing
                    professionals need to ensure that the necessary
                    preauthorization steps are taken to avoid claim denials.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-wheelchair"></i>
                  </div>
                  <h4>
                    <a href="/">Improving Claim Submission and Follow-Up</a>
                  </h4>
                  <p>
                    RCM services ensure timely and accurate submission of claims
                    to insurance providers. They also manage follow-up
                    activities, addressing denials and working to resolve issues
                    promptly.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-patch-exclamation-fill"></i>
                  </div>
                  <h4>
                    <a href="/">Reducing Billing Errors</a>
                  </h4>
                  <p>
                    By employing skilled professionals and advanced
                    technologies, RCM services aim to minimize billing errors,
                    ensuring that claims are accurate and compliant with coding
                    and regulatory standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="appointment" className="appointment section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Job Search Form</h2>
              <p>
                Its is form used by job seekers to provide relevant information
                to potential employers or job placement services. This form is
                typically part of the job application process and serves as a
                standardized way for employers to collect essential details from
                candidates like Personal Information,Work Experience etc, Our HR
                team will contact you.
              </p>
            </div>
            <form
              ref={form_Two}
              onSubmit={handleJobSearch}
              className="php-email-form"
            >
              <div className="row">
                <div className="col-md-4 form-group">
                  <input
                    type="text"
                    name="from_name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="from_email"
                    id="email"
                    placeholder="Your Email"
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="tel"
                    className="form-control"
                    name="phone_number"
                    id="phone"
                    placeholder="Your Phone"
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  placeholder="Why U Joining With us (Write a maximize 5 line)"
                ></textarea>
              </div>
              <div className="text-center">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </section>
        <section id="doctors" className="doctors">
          <div className="container">
            <div className="section-title">
              <h2>Why we need Reviews for Employees</h2>
              <p>
                
                we are qualified and competent to address the diverse medical
                specialities. Our experience, solutions and resources allow us
                to work flawlessly at the back-end and you can do the best at
                what you are: caring for patients. Since the start, Our
                Specialist and other experts have helped plenty of healthcare
                organizations manage their credentialing process, improve
                reimbursements, and increase their revenue stream with the
                customizable offerings..
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={user1} className="img-fluid person-img" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Rozan Chitrakar</h4>
                    <span>Sr. AR Associate (Account Receivable)</span>
                    <p>
                      I have been working with Sr. AR associate for a while now,
                      and I am extremely satisfied with their services. <br />{" "}
                      The AR associate has consistently demonstrated a high
                      level of professionalism, efficiency, and accuracy in
                      handling our medical billing accounts.
                    </p>
                    <div className="social">
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={user2} className="img-fluid person-img" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Pratikshya Rai</h4>
                    <span>Sr. AR Associate (Account Receivable)</span>
                    <p>
                      The AR associate demonstrated a deep understanding of the
                      complexities of medical billing, providing accurate and
                      timely solutions to any challenges that arose. Their
                      proactive approach and attention to detail have
                      significantly improved our revenue cycle management.
                    </p>
                    <div className="social">
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={user3} className="img-fluid person-img" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Ratesh Kumar Sharma</h4>
                    <span>AR Associate (Account Receivable)</span>
                    <p>
                      To be honest company is good but i don,t like HR and heigh
                      authorities they miss behave to employers and Etc.{" "}
                    </p>
                    <div className="social">
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={user4} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Aasim shaikh</h4>
                    <span>Sr. AR Associate (Account Receivable)</span>
                    <p>
                      I have 7.5 year of Experience in Medical Billing this
                      envirments and working experience is good and also having
                      knowledge about Insurance, HMOs & PPOs etc.
                    </p>
                    <div className="social">
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px", color: "gold" }}
                      ></i>
                      <i
                        className="fas fa-star"
                        style={{ fontSize: "35px" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title">
              <h2>Contact</h2>
              <p>
                you might have specific reasons or inquiries related to their
                services. which typically involve services related to medical
                billing, coding, and financial management for healthcare
                providers.
              </p>
            </div>
          </div>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                title="google-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1568.7997235964444!2d77.37574984478695!3d28.616727936064986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce55f92cc807f%3A0xfe9e6ce1bb3a11e4!2sEastern%20Software%20Systems%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1707142839301!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <a href="https://www.fnfgo.com/">FNF Mods</a>
            </div>
          </div>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>
                      B-71,B Block, Sector 63, Noida, <br></br> Uttar Pradesh
                      201307.
                    </p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>hr@r1technologiesservices.com</p>
                  </div>
                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+91 98707 40027</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mt-5 mt-lg-0">
                <form
                  className="php-email-form"
                  ref={form}
                  onSubmit={handleSendMessage}
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="from_name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="from_email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      id="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>R1 technologies Services</h3>
                  <p>
                    B-71, B Block, Sector 63, Noida,
                    <br />
                    Uttar Pradesh 201307.
                    <br />
                    <br />
                    <strong>Phone:</strong> +91 98707 40027.
                    <br />
                    <strong>Email:</strong> hr@r1technologiesservices.com
                    <br />
                  </p>
                  <div className="social-links mt-3">
                    <a
                      href="https://www.facebook.com/groups/577400563258704"
                      className="facebook"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="/" className="instagram">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="/" className="twitter">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="/" className="google-plus">
                      <i className="bi bi-skype"></i>
                    </a>
                    <a href="/" className="linkedin">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#about">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#doctor">Doctors</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Our Newsletter</h4>
                <p>
                News on emerging trends and technologies in RCM
                </p>
                <form>
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>R1 technologies Services</span>
            </strong>
            . All Rights Reserved (Since-2018)
          </div>
        </div>
      </footer>
      <div id="preloader"></div>
      <a
        href="#home"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default App;
